<template>
  <div class="balanceRecord">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>积分记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>记录类型:</span>
      <el-select
        @clear="onclear"
        v-model="list.src"
        placeholder="请选择记录类型"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getdetail()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table border :data="detailList" style="width: 100%">
      <el-table-column prop="cur" label="修改前" width="width">
      </el-table-column>
      <el-table-column prop="change" label="修改金额" width="width">
      </el-table-column>
      <el-table-column prop="end" label="修改后" width="width">
      </el-table-column>
      <el-table-column prop="src" label="修改类型" width="width">
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="width">
      </el-table-column>
      <el-table-column prop="addTime" label="修改时间" width="width">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { integralRecord } from "../../api/vip";
export default {
  name: "balanceRecord",
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      options: [
        { value: 1, label: "注册" },
        { value: 2, label: "下单" },
        { value: 3, label: "优惠券" },
        { value: 4, label: "赠送" },
        { value: 5, label: "管理员添加" },
        { value: 6, label: "分享" },
        { value: 7, label: "退款" },
        { value: 8, label: "其他" },
        { value: 8, label: "消费" },
        { value: 8, label: "管理员减少" },
      ],
      list: {
        src: null,
        currentPage: 1,
        pageSize: 5,
      },
      detailList: [],
      pagination: {},
    };
  },
  created() {
    this.getdetail();
  },
  methods: {
    onclear() {
      this.list.src = null;
    },
    async getdetail() {
      const { data } = await integralRecord({
        ...this.list,
        userId: Number(this.userId),
      });
      if (data.code == 0) {
        this.pagination = data.pagination;
        this.detailList = data.list;
      } else {
        this.$message, error(data.msg);
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getdetail();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getdetail();
    },
  },
};
</script>
<style lang="less" scoped>
.balanceRecord {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .tbody {
        .el-button {
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>